import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Checkbox, CheckboxStateless } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "checkbox"
    }}>{`Checkbox`}</h1>
    <p>{`Checkbox component which keeps its own state. To keep your own state, use CheckboxStateless`}</p>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Checkbox>Not checked</Checkbox>\n<Checkbox checked>Checked</Checkbox>\n<Checkbox disabled>Disabled not checked</Checkbox>\n<Checkbox checked disabled>\n  Disabled checked\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxStateless,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox mdxType="Checkbox">Not checked</Checkbox>
  <Checkbox checked mdxType="Checkbox">Checked</Checkbox>
  <Checkbox disabled mdxType="Checkbox">Disabled not checked</Checkbox>
  <Checkbox checked disabled mdxType="Checkbox">
    Disabled checked
  </Checkbox>
    </Playground>
    <h2 {...{
      "id": "stateless"
    }}>{`Stateless`}</h2>
    <Playground __position={1} __code={'<CheckboxStateless>Not checked</CheckboxStateless>\n<CheckboxStateless checked>Checked</CheckboxStateless>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxStateless,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <CheckboxStateless mdxType="CheckboxStateless">Not checked</CheckboxStateless>
  <CheckboxStateless checked mdxType="CheckboxStateless">Checked</CheckboxStateless>
    </Playground>
    <h2 {...{
      "id": "alternative"
    }}>{`Alternative`}</h2>
    <p>{`In flows with only a few fields you might use the prop `}<inlineCode parentName="p">{`alternative`}</inlineCode>{` to get a gray input field that stands out more.`}</p>
    <Playground __position={2} __code={'<Checkbox alternative>Not checked</Checkbox>\n<Checkbox alternative checked>\n  Checked\n</Checkbox>\n<Checkbox alternative disabled>\n  Disabled not checked\n</Checkbox>\n<Checkbox alternative checked disabled>\n  Disabled checked\n</Checkbox>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Checkbox,
      CheckboxStateless,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Checkbox alternative mdxType="Checkbox">Not checked</Checkbox>
  <Checkbox alternative checked mdxType="Checkbox">
    Checked
  </Checkbox>
  <Checkbox alternative disabled mdxType="Checkbox">
    Disabled not checked
  </Checkbox>
  <Checkbox alternative checked disabled mdxType="Checkbox">
    Disabled checked
  </Checkbox>
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Checkbox} mdxType="Props" />
    <h2 {...{
      "id": "stateless-component-props"
    }}>{`Stateless component props`}</h2>
    <Props of={CheckboxStateless} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      